import { Injectable } from "@angular/core";
import { Servers } from "../../config/api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl = Servers.Sigma.baseUrl;
  private recuperacionUrl = Servers.Sigma.baseUrl+"password_reset_email/";

  constructor(private http: HttpClient) { }

  login(data): Observable<any> {
    let body = data;
    return this.http
      .post<any[]>(this.baseUrl + 'login' , body);
  }

  recuperacion(data): Observable<any> {
    let body = data;
    return this.http
      .post<any[]>(this.recuperacionUrl , body);
  }

  resetPass(credentials): Observable<any> {
    var body = {
      password: credentials.password,
      token: credentials.token,
    }
    var resetPassUrl = ""
    resetPassUrl = this.recuperacionUrl+"confirm/"
    return this.http.post(resetPassUrl,body);
  }

  getRolesAll(): Observable<any> {
    return this.http
      .get<any[]>(this.baseUrl + 'usuario/roles');
  }
}
