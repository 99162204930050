import { Injectable } from "@angular/core";
import { Servers } from "../../config/api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PropietariosService {
  private baseUrl = Servers.Sigma.baseUrl;

  constructor(private http: HttpClient) { }

  registrar(data): Observable<any> {
    let body = data;
    return this.http
      .post<any[]>(this.baseUrl + 'register' , body);
  }

  obtener(): Observable<any> {
    return this.http.get(this.baseUrl + 'propietario/all');
  }

  eliminar(id): Observable<any> {
    return this.http.delete(this.baseUrl + 'propietario/eliminar/' + id);
  }

  obtenerOne(id): Observable<any> {
    return this.http.get(this.baseUrl + 'propietario/' + id);
  }

  editar(id, data): Observable<any> {
    let body = data;
    return this.http.put(this.baseUrl + 'propietario/editar/' + id, body);
  }

  enviarCantidad(data): Observable<any> {
    return this.http.post(this.baseUrl + 'propietario/cantidades', data);
  }
  enviarRestricciones(data): Observable<any> {
    return this.http.post(this.baseUrl + 'propietario/restricciones', data);
  }

  getCantidades(id): Observable<any> {
    return this.http.get(this.baseUrl + 'propietario/cantidades/all/'+id);
  }
  getRestricciones(id): Observable<any> {
    return this.http.get(this.baseUrl + 'propietario/restricciones/all/'+id);
  }
  eliminarCantidad(id): Observable<any> {
    return this.http.delete(this.baseUrl + 'propietario/cantidad/eliminar/'+id);
  }
  eliminarRestriccion(id): Observable<any> {
    return this.http.delete(this.baseUrl + 'propietario/restricciones/eliminar/'+id);
  }

  editarCantidad(id,body): Observable<any> {
    return this.http.put(this.baseUrl + 'propietario/cantidades/editar/'+id,body);
  }

}
